import STORAGE_ITEMS from "../../../shared/data/items";
import React, {memo, useEffect, useMemo, useState} from "react";
import {useWishlistStore} from "../store";
import {ItemType} from "../../../types/Wishlist";
import {addItemToWishlist} from "../../../components/Wishlist/WishListServices";
import useUser from "../../../hooks/useUser";
import {enqueueSnackbar} from "notistack";


const WishlistAllItemsList = memo(() => {
  const { user, selectedGuild } = useUser();
  const isLocked = useWishlistStore((store) => store.isLocked);
  const type = useWishlistStore((store) => store.type);
  const searchQuery = useWishlistStore((store) => store.searchQuery);
  const [localPage, setLocalPage] = useState(0);

  const items = useMemo(() => {
    return STORAGE_ITEMS[type]
      .filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
      .slice(0, (localPage + 1) * 10);
  }, [type, searchQuery, localPage]);

  useEffect(() => {
    setLocalPage(0);
  }, [searchQuery]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollHeight - (scrollTop + clientHeight) < 20) {
      setLocalPage((prev) => Math.min(prev + 1, Math.ceil(STORAGE_ITEMS[type].length / 10) - 1));
    }
    if (scrollTop < 10) {
      setLocalPage((prev) => Math.max(prev - 1, 0));
    }
  }

  const handleAddToWishlist = async (item: ItemType) => {
    if (!user || !selectedGuild) {
      enqueueSnackbar("User or guild not found", { variant: "error" });
      return;
    }
    if (isLocked) {
      enqueueSnackbar("Your wishlist is locked.", { variant: "error" });
      return;
    }
    try {
      await addItemToWishlist(user.uid, selectedGuild, item);
      enqueueSnackbar("Item added to wishlist", { variant: "success" });
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Error adding item to wishlist";
      enqueueSnackbar(errorMessage, { variant: "error" });
      console.error("Error adding item to wishlist:", error);
    }
  };

  return (
    <div
      className="flex flex-col gap-2 h-80 overflow-y-auto bg-base-100 py-4 pl-4 pr-3"
      onScroll={handleScroll}
    >
      {items.map((item) => (
        <div
          key={item.name}
          className="flex items-center gap-2 justify-start p-2 bg-base-200 rounded-md"
        >
          <img
            src={`/images/new/${item.imageFilename}`}
            alt={item.name}
            className="h-12 w-12 rounded-md"
          />
          <a
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold text-start hover:underline text-primary"
          >
            {item.name}
          </a>
          <button
            onClick={() => handleAddToWishlist(item)}
            disabled={isLocked}
            className="btn btn-success w-32 btn-sm rounded ml-auto"
          >
            Add to Wishlist
          </button>
        </div>
      ))}
      {items.length === 0 && (
        <div className="text-center text-secondary-content/70 mt-4">
          No items found.
        </div>
      )}
    </div>
  )
})

export {
  WishlistAllItemsList as default,
}
