// src/components/Pricing.tsx
import * as React from 'react';
import {RiCheckboxCircleFill} from "@remixicon/react";
import {useNavigate} from "react-router-dom";
import useUser from "../../../hooks/useUser";

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      'Up to 2 active sessions',
      'Up to 2 teams',
      'Up to 3 active events',
      'Core DKP features',
      'Ad-free experience',
      'Lag-free infrastructure',
    ],
    notAvailable: [
      'Discord bot integration',
      'Discord bid/roll commands',
      'Discord DKP management',
      'Automated event hooks',
      'Premium Discord role',
    ]
  },
  {
    title: 'Premium',
    subheader: 'Most Popular',
    price: '9.99',
    description: [
      'Unlimited active sessions',
      'Unlimited teams',
      'Unlimited events',
      'DKP features',
      'Ad-free experience',
      'Lag-free infrastructure',
      'Discord bot integration',
      'Discord bid/roll commands',
      'Discord DKP management',
      'Automated event hooks',
      'Premium Discord role',
    ],
    notAvailable: [],
  },
];

export default function Pricing() {
  const navigate = useNavigate();
  const { user } = useUser();

  const handleLogin = () => {
    if (user) {
      navigate("/");
      return;
    }
    navigate("/login");
  }

  return (
    <section className="w-full bg-base-200 lg:px-20 lg:py-12 p-4">
      <h2 className="skranji-bold text-4xl mb-4">Pricing</h2>
      <div className="grid lg:grid-cols-2 gap-4">
        {tiers.map((tier) => (
          <div key={tier.title} className="p-6 rounded-lg bg-base-100">
            <h4 className="text-lg mb-2">{tier.title}</h4>
            <h5 className="text-mono text-5xl font-bold text-[#FBC755]">${tier.price}</h5>
            <div className="flex flex-col gap-2 mt-4">
              {tier.description.map((feature) => (
                <p className="text-mono text-base flex items-center gap-2">
                  <RiCheckboxCircleFill className="text-green-500" />
                  {feature}
                </p>
              ))}
              {tier.notAvailable.map((feature) => (
                <p className="text-mono text-base flex items-center gap-2">
                  <RiCheckboxCircleFill className="text-gray-700" />
                  {feature}
                </p>
              ))}
              {tier.title === 'Free' && (
                <button
                  onClick={handleLogin}
                  className="btn btn-outline border-[#FBC755] hover:bg-[#FBC755] hover:border-[#FBC755] text-[#FBC755] mt-4 w-full"
                >
                  Start using for free
                </button>
              )}
              {tier.title === 'Premium' && (
                <button
                  className="btn btn-outline border-[#FBC755] hover:bg-[#FBC755] hover:border-[#FBC755] text-[#FBC755] mt-4 w-full"
                  onClick={() => window.open('https://ko-fi.com/lootmanager', '_blank')}
                >
                  Support on Ko-fi
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
