import TutorialPopover from "../../../components/common/TutorialPopover";
import React from "react";


function WishlistLock() {

  return (
    <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-base-200/50 z-30">
      <div className="flex flex-row gap-2 items-center">
        <div className="alert alert-warning rounded text-lg">
          Your wishlist has been locked by an admin. Please contact your guild administrators to unlock it.
        </div>
        <TutorialPopover
          title="Locked Wishlist"
          steps={[{
            title: "Locked wishlists prevent changes during important events like raids or loot distribution"
          }]}
        />
      </div>
    </div>
  )
}

export {
  WishlistLock as default,
}
