import React, { useEffect, useState } from "react";
import { onSnapshot, collection } from "firebase/firestore";
import { db } from "../../firebase";
import useUser from "../../hooks/useUser";
import useModal from "../../widgets/modal/Modal";

const KOFI_VERIFICATION_TOKEN = process.env.REACT_APP_KOFI_VERIFICATION_TOKEN;
const KOFI_WEBHOOK_URL = process.env.REACT_APP_KOFI_WEBHOOK_URL;

const getPaymentTypeColor = (type: string): string => {
  switch (type) {
    case "Donation":
      return "bg-green-200 border-green-500 text-green-800";
    case "Subscription":
      return "bg-blue-200 border-blue-500 text-blue-800";
    case "Shop Order":
      return "bg-yellow-200 border-yellow-500 text-yellow-800";
    default:
      return "bg-gray-200 border-gray-500 text-gray-800";
  }
};

const DevKofiPayments: React.FC = () => {
  const { user } = useUser();
  const [payments, setPayments] = useState<any[]>([]);
  const { open, close, Modal } = useModal();
  const [selectedPayment, setSelectedPayment] = useState<any | null>(null);

  useEffect(() => {
    if (!user) {
      console.log("No user detected, skipping Firestore listener.");
      return;
    }

    const paymentsRef = collection(db, "kofiPayments");
    const unsubscribe = onSnapshot(
      paymentsRef,
      (snapshot) => {
        const dataArr: any[] = [];
        snapshot.forEach((doc) => {
          dataArr.push({ id: doc.id, ...doc.data() });
        });
        setPayments(dataArr);
      },
      (error) => {
        console.error("Error fetching snapshot:", error);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [user]);

  const handleViewDetails = (payment: any) => {
    setSelectedPayment(payment);
    open();
  };

  return (
    <div className="relative">
      <div className="container mx-auto max-w-6xl px-4 py-6 md:mt-0 mt-14 flex flex-col gap-4">
        <div className="bg-base-300 p-4 relative w-full rounded-md shadow-lg">
          <h6 className="text-2xl font-bold mb-2 flex justify-between items-center">
            Ko-fi Payments
            <span className="badge badge-success">{payments.length} Payments</span>
          </h6>
          <p className="text-sm text-gray-400">Verification Token: {KOFI_VERIFICATION_TOKEN}</p>
          <p className="text-sm text-gray-400 mb-4">Webhook URL: {KOFI_WEBHOOK_URL}</p>
          <div className="my-3 divider" />
          {payments.length === 0 ? (
            <div className="text-center text-sm text-gray-400">
              No payments received yet.
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {payments.map((payment) => (
                <div
                  key={payment.id}
                  className={`card border p-4 rounded-md shadow-md cursor-pointer hover:shadow-lg transition-shadow duration-200 ${getPaymentTypeColor(
                    payment.type
                  )}`}
                  onClick={() => handleViewDetails(payment)}
                >
                  <h5 className="text-lg font-bold mb-2">{payment.type}</h5>
                  <p className="text-sm">From: {payment.from_name}</p>
                  <p className="text-sm">
                    Amount: {payment.amount} {payment.currency}
                  </p>
                  <p className="text-xs text-gray-600">
                    {payment.timestamp?.toDate
                      ? payment.timestamp.toDate().toLocaleString()
                      : payment.timestamp}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Modal for Payment Details */}
      <Modal>
        {selectedPayment && (
          <div className="bg-base-100 rounded-md shadow-2xl px-8 py-6">
            <h6 className="text-2xl font-bold mb-4">Payment Details</h6>
            <p className={`text-lg font-semibold ${getPaymentTypeColor(selectedPayment.type)}`}>
              {selectedPayment.type}
            </p>
            <div className="mt-4">
              <p className="text-sm">
                <span className="font-bold">From:</span> {selectedPayment.from_name}
              </p>
              <p className="text-sm">
                <span className="font-bold">Amount:</span> {selectedPayment.amount}{" "}
                {selectedPayment.currency}
              </p>
              <p className="text-sm">
                <span className="font-bold">Message:</span> {selectedPayment.message || "No message provided"}
              </p>
              <p className="text-sm">
                <span className="font-bold">Timestamp:</span>{" "}
                {selectedPayment.timestamp?.toDate
                  ? selectedPayment.timestamp.toDate().toLocaleString()
                  : selectedPayment.timestamp}
              </p>
              {selectedPayment.tier_name && (
                <p className="text-sm">
                  <span className="font-bold">Tier:</span> {selectedPayment.tier_name}
                </p>
              )}
            </div>
            <div className="flex flex-row justify-end gap-2 mt-6">
              <button
                className="btn btn-outline text-secondary-content/70 rounded"
                onClick={close}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default DevKofiPayments;
