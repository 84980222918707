import {Timestamp} from "firebase/firestore";
import React, {useMemo, useState} from "react";
import {useWishlistStore} from "../store";
import {WishlistItem} from "../../../types/Wishlist";
import {removeItemFromWishlist} from "../../../components/Wishlist/WishListServices";
import useUser from "../../../hooks/useUser";
import {enqueueSnackbar} from "notistack";
import useModal from "../../../widgets/modal/Modal";


const WishlistSavedItemsList = () => {
  const { user, selectedGuild } = useUser();
  const items = useWishlistStore((store) => store.items);
  const isLocked = useWishlistStore((store) => store.isLocked);
  const searchQuery = useWishlistStore((store) => store.searchQuery);

  const [toRemove, setToRemove] = useState<WishlistItem | null>(null);
  const { open, close, Modal } = useModal();

  const filteredItems = useMemo(() => {
    return items.filter((item) => item.itemName.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [items, searchQuery]);

  const handleRemoveFromWishlist = async () => {
    if (!user || !selectedGuild) {
      enqueueSnackbar("User or guild not found", { variant: "error" });
      close();
      return;
    }
    if (isLocked) {
      enqueueSnackbar("Your wishlist is locked.", { variant: "error" });
      close();
      return;
    }

    try {
      await removeItemFromWishlist(user.uid, selectedGuild, toRemove!);
      close();
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Error removing item from wishlist";
      enqueueSnackbar(errorMessage, { variant: "error" });
      close();
      console.error("Error removing item from wishlist:", error);
    }
  };

  return (
    <div className="flex flex-col gap-2 h-80 overflow-y-auto bg-base-100 py-4 pl-4 pr-3">
      {filteredItems.map((item) => (
        <div
          key={item.itemName}
          className="flex items-center gap-2 justify-start p-2 bg-base-200 rounded-md"
        >
          <img
            src={item.imageUrl}
            alt={item.itemName}
            className="h-12 w-12 rounded-md"
          />
          <div>
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold text-start hover:underline text-primary"
            >
              {item.itemName}
            </a>
            <p className="text-secondary-content/70">Added: {item.addedAt instanceof Timestamp ? item.addedAt.toDate().toLocaleString() : "Just now"}</p>
          </div>
          <button
            onClick={() => {
              setToRemove(item);
              open();
            }}
            disabled={isLocked}
            className="btn btn-error btn-outline w-32 ml-auto rounded btn-sm"
          >
            Remove
          </button>
        </div>
      ))}
      {items.length === 0 && (
        <p className="text-center text-secondary-content/70 mt-4">
          No items in your wishlist yet
        </p>
      )}
      {(items.length !== 0 && filteredItems.length === 0) && (
        <div className="text-center text-secondary-content/70 mt-4">
          No items found matching "{searchQuery}"
        </div>
      )}
      {Modal({
        children: (
          <div className="bg-base-100 rounded-md shadow-2xl px-12 py-8">
            <h1 className="text-2xl font-bold mb-2">Do you want to remove {toRemove?.itemName}?</h1>
            <p className="text-lg">Removing this item will not automatically refund your token.<br/>You'll need to contact an admin to get your
              token back.</p>
            <div className="flex flex-row justify-end gap-2 mt-8">
              <button onClick={close} className="btn btn-outline text-secondary-content/70 rounded">Cancel</button>
              <button onClick={handleRemoveFromWishlist} className="btn btn-error rounded">Remove</button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export {
  WishlistSavedItemsList as default,
}
