import {ItemType} from "../../types/Wishlist";
import itemsDataNew from "../../items.json";

function toItemType(item: unknown): ItemType | null {
  if (typeof item !== "object" || item === null) {
    return null;
  }
  const correctItem = item as ItemType;
  return {
    name: correctItem.name,
    imageFilename: correctItem.imageFilename.replace(/'/g, "_").replace(/\s+/g, "_"),
    url: correctItem.url,
    tokenCost: correctItem.tokenCost || 1,
  };
}

const throneAndLibertyItemsRaw = Array.isArray(itemsDataNew) ? itemsDataNew : (itemsDataNew as { items?: any[] }).items ?? [];
const throneAndLibertyItems: ItemType[] = throneAndLibertyItemsRaw
  .map(toItemType)
  .filter((item): item is ItemType => item !== null);

export enum ItemsStorage {
  THRONE_AND_LIBERTY = "throne_and_liberty",
  OTHER = "other",
}

const STORAGE_ITEMS: Record<ItemsStorage, ItemType[]> = {
  [ItemsStorage.THRONE_AND_LIBERTY]: throneAndLibertyItems,
  [ItemsStorage.OTHER]: [],
}

export {
  STORAGE_ITEMS as default,
};
