import React from "react";
import {useDashboardStore, UserProfile} from "../store";
import STORAGE_PROFILE_LAYOUT, {LayoutItem} from "../../../shared/data/profile";
import {RiEditFill} from "@remixicon/react";
import useModal from "../../../widgets/modal/Modal";
import EditProfile from "./EditProfile";


function ProfileRow({ item, data }: { item: LayoutItem, data: UserProfile }) {
  return (
    <div>
      <p className="text-secondary-content text-base">{item.name}</p>
      {item.drawer && (item.drawer(data[item.id as keyof UserProfile]))}
      {!item.drawer && (
        <p className="text-lg font-bold">{data[item.id as keyof UserProfile] || item.nonValue}</p>
        )}
    </div>
  )
}

function Profile() {
  const currentUser = useDashboardStore((store) => store.userProfile);
  const { open, close, Modal } = useModal();

  if (!currentUser) {
    return null;
  }

  return (
    <div className="w-full flex flex-col">
      <div>
        <div className="bg-base-300 p-4">
          <div className="flex flex-row gap-4 justify-between items-center">
            <h6 className="text-xl font-bold flex flex-row gap-2 items-center">
              Character Details
            </h6>
            <button className="btn btn-sm rounded" onClick={open}><RiEditFill size={20} />Edit Profile</button>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4 bg-base-200 p-4 rounded-md">
            {STORAGE_PROFILE_LAYOUT['throne_and_liberty'].map((item) => (
              <ProfileRow key={item.id} item={item} data={currentUser}/>
            ))}
          </div>
        </div>
      </div>
      <Modal>
        <EditProfile close={close} />
      </Modal>
    </div>
  )
}

export {
  Profile as default,
}
