// src/components/FAQ.tsx
import * as React from 'react';

const faqs = [
  {
    id: 'panel1',
    question: 'How do I get started with LootManager?',
    answer:
      'Getting started is simple - sign in with Discord, create your guild, and invite your members. Our intuitive interface guides you through setting up your first raid session and DKP system in minutes.',
  },
  {
    id: 'panel2',
    question: 'What features are included in the Premium tier?',
    answer:
      'Premium members get unlimited sessions, teams and events, full Discord bot integration for bidding and DKP management and automated event hooks. The Discord bot enables direct bidding through commands and automated notifications.',
  },
  {
    id: 'panel3',
    question: 'How does the bidding system work?',
    answer:
      'Our advanced bidding system features real-time updates, wishlist verification, private bidding options, and anti-snipe protection. The sharded architecture ensures smooth performance even during peak raid times.',
  },
  {
    id: 'panel4',
    question: 'What DKP management tools are available?',
    answer:
      'LootManager offers preset DKP rewards, decay systems, flexible point distribution, and detailed tracking. Admins can easily split or distribute DKP across raid members and export comprehensive logs for analysis.',
  },
  {
    id: 'panel5',
    question: 'How can I get support?',
    answer:
      'Join our active Discord community for immediate assistance and feature discussions. Our development team actively implements user feedback and provides quick responses to enhance your experience.',
  },
];

export default function FAQ() {
  const [expanded, setExpanded] = React.useState<string[]>([]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(
        isExpanded
          ? [...expanded, panel]
          : expanded.filter((item) => item !== panel),
      );
    };

  return (
    <section className="w-full bg-base-300 lg:px-20 lg:py-12 p-4">
      <h2 className="skranji-bold text-4xl mb-4">
        Frequently Asked Questions
      </h2>
      <div>
        {faqs.map((faq) => (
          <div className="collapse collapse-arrow bg-base-200" key={faq.id}>
            <input type="radio" name="faq-group"/>
            <div className="collapse-title font-semibold text-lg">
              {faq.question}
            </div>
            <div className="collapse-content">
              <p className="py-4 text-lg">{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
