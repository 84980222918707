import TutorialPopover from "../../../components/common/TutorialPopover";
import React from "react";
import useUser from "../../../hooks/useUser";
import {useSticky} from "../../../shared/hooks/useSticky";
import {DownOutlined} from "@ant-design/icons";
import {RiAddBoxFill, RiGamepadFill} from "@remixicon/react";
import {useNavigate} from "react-router-dom";
import {GuildTrial, useDashboardStore} from "../store";
import {doc, getDoc, serverTimestamp, Timestamp, updateDoc} from "firebase/firestore";
import {db} from "../../../firebase";
import AdminComponent from "../../../shared/providers/AdminComponent";
import useModal from "../../../widgets/modal/Modal";
import {enqueueSnackbar} from "notistack";


function DashboardHeader() {
  const navigate = useNavigate();
  const isSticky = useSticky(5);
  const { user, selectedGuild } = useUser();
  const guildData = useDashboardStore((state) => state.guildData);
  const setGuildData = useDashboardStore((state) => state.setGuildData);
  const { open, close, Modal } = useModal();

  const activateTrial = async () => {
    if (!user || !selectedGuild) return;

    try {
      const guildRef = doc(db, "guilds", selectedGuild);
      const guildDoc = await getDoc(guildRef);
      const guildData = guildDoc.data();

      // Check if guild is already subscribed
      if (guildData?.isSubscribed) {
        enqueueSnackbar("This guild already has an active subscription. Trial activation is not needed.", {variant: "info"});
        return;
      }

      // Check existing trial
      if (guildData?.trial) {
        await updateDoc(guildRef, {
          trial: {
            ...guildData.trial,
            hasUsedTrial: true,
          },
          isSubscribed: false,
        });

        enqueueSnackbar("This guild has already used its trial period.", {variant: "info"});
        return;
      }

      const expiresAt = Timestamp.fromMillis(Date.now() + 48 * 60 * 60 * 1000);

      const trialData: GuildTrial = {
        activatedAt: serverTimestamp(),
        activatedBy: user.uid,
        expiresAt,
        hasUsedTrial: true,
      };

      await updateDoc(guildRef, {
        trial: trialData,
        isSubscribed: false,
      });

      setGuildData({
        trial: trialData,
        isSubscribed: false,
      });
      enqueueSnackbar("Trial period activated successfully! Bot features are now enabled for 48 hours.", {variant: "success"});
    } catch (error) {
      console.error("Error activating trial:", error);
      enqueueSnackbar("Failed to activate trial. Please try again.", {variant: "error"});
    }
  };

  return (
    <div
      className={`bg-opacity-25 z-50 sticky md:top-0 top-14 w-full bg-cover bg-bottom bg-no-repeat transition-all ${isSticky ? "h-16" : "h-48"}`}
      style={{backgroundImage: 'url(/images/newsite/bkg.jpg)'}}
    >
      <div className="w-full h-full bg-black bg-opacity-50 flex justify-center items-end">
        <div
          className="container mx-auto max-w-6xl pb-4 px-4 flex flex-row justify-between items-center w-full">
          <h3 className="font-bold text-xl flex flex-row items-center gap-2">
            Dashboard
            {/*<TutorialPopover*/}
            {/*  title="Your Wishlist"*/}
            {/*  steps={[{*/}
            {/*    title: "Items in your wishlist have priority during loot distribution."*/}
            {/*  }]}*/}
            {/*/>*/}
          </h3>
          <div className="flex flex-row gap-2">
            <AdminComponent>
              {!guildData?.isSubscribed &&
                (!guildData?.trial?.hasUsedTrial ||
                  (guildData?.trial?.expiresAt?.toDate().getTime() > Timestamp.now().toDate().getTime())) && (
                  <button className="btn btn-sm btn-success" onClick={() => {
                    if (guildData?.trial?.hasUsedTrial) {
                      return;
                    }
                    open();
                  }}>
                    {guildData?.trial && (
                      <p>Discord Bot Trial ({Math.ceil(
                        (guildData.trial.expiresAt.toDate().getTime() -
                          Timestamp.now().toDate().getTime()) /
                        (1000 * 60 * 60)
                      )}h)</p>
                    )}
                    {!guildData?.trial && (
                      <>
                        <RiAddBoxFill/> Discord Bot Trial
                      </>
                    )}
                  </button>
                )}
            </AdminComponent>
            <button className="btn btn-sm btn-outline" onClick={() => navigate("/sessions")}><RiGamepadFill/>Sessions</button>
            <div className="flex md:flex-row flex-col md:items-center items-end gap-2">
              <div className="dropdown">
                <button className="btn btn-sm btn-outline">Throne and Liberty <DownOutlined/></button>
                <div tabIndex={0} className="dropdown-content z-50 w-64 menu p-0 bg-base-100 shadow">
                  <button className="btn btn-sm btn-active">Throne and Liberty</button>
                  <button className="btn btn-sm btn-primary">Other <div className="badge badge-info rounded">WIP</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal>
        <div className="bg-base-100 rounded-md shadow-2xl px-12 py-8">
          <h6 className="text-2xl font-bold mb-2">
            Activate Trial Period
          </h6>
          <p className="text-lg">
            Are you sure you want to activate the 48-hour trial period? This
            can only be done once.
          </p>
          <div className="flex flex-row justify-end gap-2 mt-8">
            <button
              className="btn btn-outline text-secondary-content/70 rounded"
              onClick={close}
            >
              Cancel
            </button>
            <button
              className="btn btn-success rounded"
              onClick={() => {
                close();
                activateTrial();
              }}
            >
              Activate Trial
            </button>
          </div>
        </div>
      </Modal>
    </div>
)
}

export {
  DashboardHeader as
default,
}
