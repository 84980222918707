import {RiQuestionFill} from "@remixicon/react";
import React from "react";
import {useDashboardStore} from "../store";
import useUser from "../../../hooks/useUser";
import {disableTutorials, enableTutorials} from "../../../store/tutorialSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";


function DKP() {
  const dispatch = useDispatch();
  const isTutorialEnabled = useSelector((state: RootState) => state.tutorial.isEnabled);
  const { user, loading, selectedGuild } = useUser();
  const userProfile = useDashboardStore((state) => state.userProfile);
  const currentUsername = useDashboardStore((state) => state.username);

    return (
      <div className="w-full flex flex-col">
        <div>
          <div className="bg-base-300 p-4">
            <div className="flex flex-row gap-4 justify-between items-center">
              <h6 className="text-xl font-bold flex flex-row gap-2 items-center">
                Profile
              </h6>
              <button
                onClick={() => {
                  if (isTutorialEnabled) {
                    dispatch(disableTutorials());
                    return;
                  }
                  dispatch(enableTutorials());
                }}
                className={`btn btn-sm rounded ${!isTutorialEnabled && "btn-info"}`}>
                <RiQuestionFill />{isTutorialEnabled
                ? "Tutorial Icons Enabled"
                : "Active Tutorial Help Icons"}
              </button>
            </div>
            <div className="mt-4 bg-base-200 p-4 rounded-md flex flex-row gap-4 items-center justify-between w-full">
              <div>
                <h5 className="text-lg font-bold">
                  {currentUsername}
                </h5>
                <div className="badge badge-outline rounded">
                  {user?.role || "Member"}
                </div>
              </div>
              <div className="text-4xl font-mono font-bold">
                {Math.ceil(user?.dkp || 0)} DKP
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export {
  DKP as default,
}
