import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from "../../firebase";
import useUser from "../../hooks/useUser";

const UpgradeToPremium: React.FC = () => {
    const { user, selectedGuild } = useUser();
    const [transactionId, setTransactionId] = useState<string>("");
    const [statusMessage, setStatusMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAlreadySubscribed, setIsAlreadySubscribed] = useState<boolean>(false);
    const [guildName, setGuildName] = useState<string>("");

    const getStatusMessageStyle = (message: string) => {
        if (message.includes("Ready") || message.includes("Success") || message.includes("subscribed until")) {
            return "bg-success text-success-content";
        }
        if (message.includes("Please") || message.includes("Enter")) {
            return "bg-info text-info-content";
        }
        return "bg-error text-error-content";
    };

    const premiumFeatures = [
        "Unlimited active sessions",
        "Unlimited teams",
        "Unlimited events",
        "Discord bot integration",
        "Discord bid/roll commands",
        "Discord DKP management",
        "Automated event hooks",
        "Premium Discord role",
      ];

    useEffect(() => {
        const checkSubscriptionStatus = async () => {
            setStatusMessage("");
            setIsAlreadySubscribed(false);
            setGuildName("");

            console.log('Current User:', user);
            console.log('Selected Guild:', selectedGuild);

            if (!user || !selectedGuild) {
                setStatusMessage("Select a guild to begin the upgrade process");
                return;
            }

            const hasPermission = user.memberships?.some(
                m => m.guildId === selectedGuild && m.status === "approved"
            );

            if (!hasPermission) {
                setStatusMessage("You need admin permissions to manage guild subscriptions");
                return;
            }

            try {
                const guildRef = doc(db, "guilds", selectedGuild);
                const guildSnapshot = await getDoc(guildRef);

                if (!guildSnapshot.exists()) {
                    setStatusMessage("Guild information unavailable");
                    return;
                }

                const guildData = guildSnapshot.data();
                setGuildName(guildData.name || selectedGuild);

                const now = new Date();
                const subscriptionStatus = {
                    isSubscribed: guildData.isSubscribed || false,
                    expiresAt: guildData.subscriptionExpiresAt?.toDate(),
                    currentTime: now
                };

                console.log('Subscription Status:', subscriptionStatus);

                if (subscriptionStatus.isSubscribed && subscriptionStatus.expiresAt && subscriptionStatus.expiresAt > now) {
                    setIsAlreadySubscribed(true);
                    const expiryDate = subscriptionStatus.expiresAt.toLocaleDateString();
                    setStatusMessage(`${guildData.name} is enjoying Premium benefits until ${expiryDate}`);
                } else {
                    setIsAlreadySubscribed(false);
                    setStatusMessage("Ready to unlock Premium features for your guild!");
                }
            } catch (error) {
                console.error("Subscription check failed:", { error, selectedGuild, user });
                setStatusMessage("Connection error - please try again");
            }
        };

        checkSubscriptionStatus();
    }, [selectedGuild, user]);

    const handleUpgrade = async () => {
        setIsLoading(true);
        setStatusMessage("");

        if (!transactionId?.trim()) {
            setStatusMessage("Enter your Ko-fi transaction ID to continue");
            setIsLoading(false);
            return;
        }

        try {
            const functions = getFunctions();
            const verifyPayment = httpsCallable(functions, 'verifyKofiPayment');

            const result = await verifyPayment({
                transactionId: transactionId.trim(),
                guildId: selectedGuild
            });

            const { success, message, expirationDate } = result.data as {
                success: boolean;
                expirationDate: string;
                message: string;
            };

            setStatusMessage(success ? "Premium upgrade successful! Enjoy your new features!" : message);

            if (success) {
                setIsAlreadySubscribed(true);
                setTransactionId("");
            }
        } catch (error: any) {
            console.error("Verification failed:", error);
            setStatusMessage("Verification failed - please check your transaction ID");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container mx-auto max-w-3xl p-6 mt-10 bg-base-300 rounded-lg shadow-xl">
          <h2 className="text-3xl font-bold mb-6 text-center text-primary">Upgrade to Premium</h2>
    
          {isAlreadySubscribed ? (
            <div className="bg-success/20 p-6 rounded-lg shadow-sm text-center mb-6">
              <h3 className="text-xl font-semibold text-success">Premium Active</h3>
              <p className="text-success-content/80">
                {guildName || selectedGuild} is enjoying Premium features!
              </p>
            </div>
          ) : (
            <>
              <div className="bg-base-200 p-6 rounded-lg shadow-sm mb-6">
                <h3 className="text-xl font-semibold mb-4 text-primary">Premium Benefits</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  {premiumFeatures.map((feature) => (
                    <div key={feature} className="flex items-center gap-2">
                      <svg className="w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                      <span className="text-base-content/80">{feature}</span>
                    </div>
                  ))}
                </div>
              </div>
    
              <div className="bg-base-200 p-6 rounded-lg shadow-sm mb-6">
                <h3 className="text-xl font-semibold mb-4 text-primary">How to Subscribe</h3>
                <ol className="list-decimal list-inside space-y-3 text-base-content/80">
                  <li>
                    Visit our{" "}
                    <a
                      href="https://ko-fi.com/lootmanager"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary hover:text-primary-focus underline font-semibold"
                    >
                      Ko-fi subscription page
                    </a>
                  </li>
                  <li>Click on "Subscribe" and select the monthly Premium plan ($9.99/month)</li>
                  <li>Complete the payment process</li>
                  <li>
                    After payment, go to your{" "}
                    <a
                      href="https://ko-fi.com/home/coffeeshop"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary hover:text-primary-focus underline"
                    >
                      Ko-fi transaction history
                    </a>
                  </li>
                  <li>Find your recent "LootManager Premium - Monthly Subscription" purchase</li>
                  <li>
                    Copy the Transaction ID
                    <div className="mt-2 p-2 bg-base-300 rounded">
                      <p className="text-sm text-base-content/60 mb-1">Your Transaction ID will look like this:</p>
                      <code className="bg-base-100 px-2 py-1 rounded text-primary">
                        8eb0c743-c336-4117-bd53-76ee3f62e11d
                      </code>
                    </div>
                  </li>
                </ol>
              </div>
    
              <div className="bg-base-200 p-6 rounded-lg shadow-sm mb-6">
                <h3 className="text-xl font-semibold mb-4 text-primary">Activate Your Subscription</h3>
                <div className="flex flex-col gap-4">
                  <input
                    type="text"
                    className="input input-bordered input-primary w-full"
                    placeholder="Paste your Ko-fi Transaction ID here"
                    value={transactionId}
                    onChange={(e) => setTransactionId(e.target.value)}
                    disabled={isAlreadySubscribed || isLoading}
                  />
    
                  <button
                    className={`btn btn-primary btn-lg ${isLoading ? "loading" : ""}`}
                    onClick={handleUpgrade}
                    disabled={isLoading || isAlreadySubscribed || !transactionId.trim()}
                  >
                    {isLoading ? "Verifying..." : "Activate Premium"}
                  </button>
                </div>
              </div>
            </>
          )}
    
          {statusMessage && (
            <div className={`mt-6 p-4 rounded-lg text-center ${getStatusMessageStyle(statusMessage)}`}>
              {statusMessage}
            </div>
          )}
        </div>
      );
    };

export default UpgradeToPremium;
