import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Link as RouterLink } from 'react-router-dom';
import Discord from '@mui/icons-material/Chat';

export default function MarketingFooter() {
  return (
    <Box
      component="footer"
      sx={{
        px: 2,
        mt: 'auto',
        py: 1,
        backgroundColor: theme => theme.palette.background.paper,
        borderTop: theme => `1px solid ${theme.palette.divider}`,
      }}
      // TODO: show it on specific pages only
      // className="fixed bottom-0 w-full z-10 py-2 hidden"
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 3
          }}
        >

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Link component={RouterLink} to="/privacy-policy" color="inherit">
              Privacy Policy
            </Link>
            <Link component={RouterLink} to="/terms-of-service" color="inherit">
            Terms of Service
            </Link>
            <Link href="mailto:info@lootmanager.net" color="inherit">
              Contact Us
            </Link>
            <Link href="https://discord.gg/J3bCDN4vys" target="_blank" color="inherit">
              Discord
            </Link>
          </Box>

          <Stack direction="row" spacing={2}>
            <IconButton
              color="inherit"
              href="https://discord.gg/J3bCDN4vys"
              target="_blank"
              aria-label="Discord"
            >
              <Discord />
            </IconButton>
          </Stack>

          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} LootManager
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
