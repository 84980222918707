// src/components/UpdateNotification.tsx
import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { Box, Button, Typography, Link, Backdrop, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';

const DISCORD_LINK = 'https://discord.gg/J3bCDN4vys';

const UpdateNotification: React.FC = () => {
  const [showUpdatePrompt, setShowUpdatePrompt] = useState(false);
  const [newVersion, setNewVersion] = useState<string>('');
  const theme = useTheme();

    // Expose `setShowUpdatePrompt` globally for testing
    useEffect(() => {
      (window as any).forceShowModal = () => setShowUpdatePrompt(true);
    }, []);

  useEffect(() => {
    const versionRef = doc(db, 'system', 'version');
    const unsubscribe = onSnapshot(versionRef, (docData) => {
      const version = docData.data()?.version;
      const currentVersion = localStorage.getItem('appVersion');
      if (version) {
        if (!currentVersion) {
          localStorage.setItem('appVersion', version);
        } else if (currentVersion !== version) {
          setNewVersion(version);
          setShowUpdatePrompt(true);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const handleUpdate = () => {
    localStorage.setItem('appVersion', newVersion);
    window.location.reload();
  };

  if (!showUpdatePrompt) return null;

  return (
    <Backdrop
      open={showUpdatePrompt}
      sx={{
        zIndex: theme.zIndex.modal,
        backgroundColor: alpha('#0E2033', 0.8),
        p: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '90%',
          maxWidth: 480,
          bgcolor: '#0E2033',
          color: '#FBC755',
          borderRadius: 2,
          boxShadow: '0 2px 8px rgba(0,0,0,0.4)',
          p: 4,
          textAlign: 'center',
          border: '1px solid rgba(251, 199, 85, 0.3)',
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 900, mb: 2 }}>
          🎉 New Version Available! 🎉
        </Typography>
        <Typography variant="body1" sx={{ color: '#FFFFFF', mb: 3 }}>
          Thank you for your continued support of Loot Manager. As tech professionals, 
          January has kept us particularly engaged with industry-wide initiatives and 
          deadlines. We're now ramping up development with a focused roadmap based on 
          your valuable input from the <strong>#feature-request</strong> channel in Discord.
        </Typography>
        <Typography variant="body2" sx={{ color: '#FFFFFF', mb: 3 }}>
          Join our{' '}
          <Link
            href={DISCORD_LINK}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: '#FBC755',
              textDecoration: 'underline',
              fontWeight: 'bold',
              '&:hover': {
                color: alpha('#FBC755', 0.8),
              },
            }}
          >
            Discord
          </Link>{' '}
          to chat with our friendly dev about your ideas and feature suggestions! We update
          this tool based on your feedback and we're always looking for ways to improve it.
        </Typography>
        <Button
          variant="contained"
          size="large"
          onClick={handleUpdate}
          sx={{
            backgroundColor: '#FBC755',
            color: '#1E1E1E',
            fontWeight: 'bold',
            px: 4,
            py: 1.5,
            '&:hover': {
              backgroundColor: alpha('#FBC755', 0.9),
            },
          }}
        >
          Update Now
        </Button>
      </Box>
    </Backdrop>
  );
};

export default UpdateNotification;
