import React from "react";
import { Navigate } from "react-router-dom";
import useUser from "../hooks/useUser";

interface ProtectedRouteProps {
  children: React.ReactNode;
  adminOnly?: boolean;
  adminOrOfficer?: boolean;
  developerOnly?: boolean; // Added developerOnly prop
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ 
  children, 
  adminOnly = false,
  adminOrOfficer = false,
  developerOnly = false // Default to false
}) => {
  const { user, loading, selectedGuild } = useUser();

  const developerUIDs = process.env.REACT_APP_DEVELOPER_UIDS?.split(",") || [];

  if (loading) return <div>Loading...</div>;
  
  if (!user) {
    return <Navigate to="/login" />;
  }

  if (!selectedGuild) {
    return <Navigate to="/select-guild" />;
  }

  const membership = user.memberships.find((m) => m.guildId === selectedGuild);

  if (!membership) {
    return <Navigate to="/login" />;
  }

  if (membership.status === "pending") {
    return <Navigate to="/pending-approval" />;
  }
  
  if (membership.status === "rejected") {
    return <Navigate to="/rejected" />;
  }

  if (adminOnly && membership.role !== "admin") {
    return <Navigate to={`/dashboard`} />;
  }

  if (adminOrOfficer && membership.role !== "admin" && membership.role !== "officer") {
    return <Navigate to={`/dashboard`} />;
  }

  if (developerOnly && !developerUIDs.includes(user.uid)) {
    console.warn("Access denied for non-developer UID:", user.uid);
    return <Navigate to={`/dashboard`} />; // Restrict access for non-developers
  }

  return <>{children}</>;
};

export default ProtectedRoute;
