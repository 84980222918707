import React, {useState} from "react";
import {doc, serverTimestamp, updateDoc} from "firebase/firestore";
import {db} from "../../../firebase";
import {useDashboardStore} from "../store";
import {RolesPicked} from "../../../shared/data/profile";
import {enqueueSnackbar} from "notistack";

// TODO: move it only for specific game
const weapons = [
  "Longbow",
  "Dagger",
  "Crossbow",
  "Greatsword",
  "Staff",
  "Wand",
  "Sword and Shield",
  "Spear"
];

function EditProfile({ close }: { close: () => void }) {
  const guildId = useDashboardStore((store) => store.guildId);
  const userProfile = useDashboardStore((store) => store.userProfile);
  const setUserProfile = useDashboardStore((store) => store.setUserProfile);

  // TODO: use form lib?
  const [selectedRoles, setSelectedRoles] = useState<string[]>(userProfile?.inGameRole || []);
  const [newPrimaryWeapon, setNewPrimaryWeapon] = useState(userProfile?.primaryWeapon || "");
  const [newSecondaryWeapon, setNewSecondaryWeapon] = useState(userProfile?.secondaryWeapon || "");
  const [newGearScore, setNewGearScore] = useState<number>(userProfile?.gearScore || 0);
  const [newInGameName, setNewInGameName] = useState<string>(userProfile?.inGameName || "");
  const [savingInGameName, setSavingInGameName] = useState<boolean>(false);


  const handleSaveInGameName = async (e: React.FormEvent) => {
    close();
    e.preventDefault();
    if (!newInGameName.trim()) {
      enqueueSnackbar("In-Game Name cannot be empty.", {variant: "info"});
      return;
    }

    if (selectedRoles.length === 0) {
      enqueueSnackbar("Please select at least one in-game role.", {variant: "info"});
      return;
    }

    setSavingInGameName(true);
    try {
      const userGuildRef = doc(db, "userGuilds", guildId!);
      const updateData = {
        inGameName: newInGameName.trim(),
        primaryWeapon: newPrimaryWeapon,
        secondaryWeapon: newSecondaryWeapon,
        gearScore: newGearScore,
        inGameRole: selectedRoles,
        timestamp: serverTimestamp(),
      };

      await updateDoc(userGuildRef, updateData);

      setUserProfile({
        inGameName: newInGameName.trim(),
        primaryWeapon: newPrimaryWeapon,
        secondaryWeapon: newSecondaryWeapon,
        gearScore: newGearScore,
        inGameRole: selectedRoles,
      });
      enqueueSnackbar("Profile updated successfully.", {variant: "success"});
    } catch (error) {
      console.error("Dashboard - Error updating profile:", error);
      enqueueSnackbar("Failed to update profile. Please try again.", {variant: "error"});
    } finally {
      setSavingInGameName(false);
    }
  };

  return (
    <div className="bg-base-200 rounded-md shadow-2xl px-12 py-8">
      <h6 className="text-2xl mb-4">
        Edit Profile
      </h6>
      <form onSubmit={handleSaveInGameName}>
        <div className="flex flex-col gap-4 lg:min-w-[620px] p-4 rounded-md">
          <div>
            <p className="text-base mb-2">In-Game Name</p>
            <input
              required
              className="input w-full"
              value={newInGameName}
              onChange={(e) => setNewInGameName(e.target.value)}
            />
          </div>
          <div>
            <p className="text-base mb-2">Primary Weapon</p>
            <select
              className="select w-full"
              value={newPrimaryWeapon}
              onChange={(e) => setNewPrimaryWeapon(e.target.value)}
              required
            >
              {weapons.map((weapon) => (
                <option key={weapon} value={weapon}>
                  {weapon}
                </option>
              ))}
            </select>
          </div>
          <div>
            <p className="text-base mb-2">Secondary Weapon</p>
            <select
              className="select w-full"
              value={newSecondaryWeapon}
              onChange={(e) => setNewSecondaryWeapon(e.target.value)}
            >
              <option value="">None</option>
              {weapons.map((weapon) => (
                <option key={weapon} value={weapon}>
                  {weapon}
                </option>
              ))}
            </select>
          </div>
          <div>
            <p className="text-base mb-2">
              Gear Score
            </p>
            <input
              type="number"
              className="input w-full"
              value={newGearScore}
              onChange={(e) => setNewGearScore(Number(e.target.value))}
            />
          </div>
          <div>
            <p className="text-base mb-2">
              In-Game Roles
            </p>
            <RolesPicked
              value={selectedRoles}
              onChange={(value: string) => {
                setSelectedRoles((prev) => {
                  if (prev.includes(value)) {
                    return prev.filter((r) => r !== value);
                  }
                  return [...prev, value];
                })
              }}
            />
          </div>
        </div>
        <div className="flex flex-row justify-end gap-2 mt-8">
          <button onClick={close} className="btn btn-outline text-secondary-content/70 rounded">
            Cancel
          </button>
          <button
            type="submit"
            disabled={savingInGameName}
            className="btn btn-success rounded"
          >
            {savingInGameName ? "Saving..." : "Save Changes"}
          </button>
        </div>
      </form>
    </div>
  )
}

export {
  EditProfile as default,
}
