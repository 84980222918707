import {ReactNode} from "react";
import {RiHealthBookFill, RiShieldFill, RiSwordFill} from "@remixicon/react";
import {FormControl} from "@mui/material";

export enum ProfileLayoutStorage {
  THRONE_AND_LIBERTY = "throne_and_liberty",
  OTHER = "other",
}

export interface LayoutItem {
  id: string;
  name: string;
  nonValue?: string;
  drawer?: (value: any) => ReactNode;
}


const IN_GAME_ROLES = ["DPS", "Healer", "Tank"];

const ROLES_COLORS = {
  'DPS': 'bg-red-600',
  'Tank': 'bg-amber-600',
  'Healer': 'bg-green-600',
}
const ROLES_ICONS = {
  'DPS': <RiSwordFill />,
  'Tank': <RiShieldFill />,
  'Healer': <RiHealthBookFill />,
}

export const RolesPicked = ({ value, onChange }: { value: string[], onChange: (value: string) => void }) => {
  return (
    <div className="flex flex-row gap-2">
      {IN_GAME_ROLES.map((role) => (
        <div
          key={role}
          onClick={() => onChange(role)}
          className={`flex cursor-pointer flex-row gap-2 items-center px-2 py-1 rounded ${value.includes(role) ? ROLES_COLORS[role as keyof typeof ROLES_COLORS] : 'bg-base-300'}`}
        >
          {ROLES_ICONS[role as keyof typeof ROLES_ICONS]} {role}
        </div>
      ))}
    </div>
  )
}

const inGameRoleDrawer = (value: string[]) => {
  return (
    <div className="flex flex-row gap-2">
      {value.map((role) => (
        <div key={role}
             className={`flex flex-row gap-2 items-center px-2 py-1 rounded ${ROLES_COLORS[role as keyof typeof ROLES_COLORS]}`}>
        {ROLES_ICONS[role as keyof typeof ROLES_ICONS]} {role}
        </div>
      ))}
    </div>
  )
}

const gearScoreDrawer = (value: number) => {
  return (
    <div className="bg-amber-800 text-amber-300 font-bold text-xl py-1 px-4 rounded w-fit">{value}</div>
  )
}

const throneAndLibertyProfileLayout: LayoutItem[] = [
  { id: "gearScore", name: "Gear Score", nonValue: "Not Set", drawer: gearScoreDrawer },
  { id: "inGameRole", name: "In-Game Roles", nonValue: "Empty", drawer: inGameRoleDrawer },
  { id: "primaryWeapon", name: "Primary Weapon", nonValue: "Not Set" },
  { id: "secondaryWeapon", name: "Secondary Weapon", nonValue: "Not Set" },
];

const STORAGE_PROFILE_LAYOUT: Record<ProfileLayoutStorage, LayoutItem[]> = {
  [ProfileLayoutStorage.THRONE_AND_LIBERTY]: throneAndLibertyProfileLayout,
  [ProfileLayoutStorage.OTHER]: [],
}

export {
  STORAGE_PROFILE_LAYOUT as default,
};