import {create} from "zustand/react";
import {ItemsStorage} from "../../../shared/data/items";
import {WishlistItem} from "../../../types/Wishlist";

interface WishlistStoreValues {
  isLoading: boolean;
  showTopItems: boolean;
  searchQuery: string;
  type: ItemsStorage;
  isLocked: boolean;
  items: WishlistItem[];
  tokenBalance: number;
  topItems: { itemName: string; count: number }[];
}

interface WishlistStoreActions {
  setIsLoading: (isLoading: boolean) => void;
  setShowTopItems: (showTopItems: boolean) => void;
  setSearchQuery: (query: string) => void;
  setType: (type: ItemsStorage) => void;
  setIsLocked: (isLocked: boolean) => void;
  setItems: (items: WishlistItem[]) => void;
  setTokenBalance: (tokenBalance: number) => void;
  setTopItems: (topItems: { itemName: string; count: number }[]) => void;
}

export const useWishlistStore = create<WishlistStoreValues & WishlistStoreActions>((set) => ({
  isLoading: true,
  showTopItems: false,
  type: ItemsStorage.THRONE_AND_LIBERTY,
  searchQuery: "",
  isLocked: false,
  items: [],
  topItems: [],
  tokenBalance: 0,
  setIsLoading: (isLoading) => set(() => ({isLoading})),
  setShowTopItems: (showTopItems) => set(() => ({showTopItems})),
  setSearchQuery: (query) => set(() => ({searchQuery: query})),
  setType: (type: ItemsStorage) => ({ type }),
  setIsLocked: (isLocked) => set(() => ({isLocked})),
  setItems: (items: WishlistItem[]) => set(() => ({items})),
  setTokenBalance: (tokenBalance) => set(() => ({tokenBalance})),
  setTopItems: (topItems) => set(() => ({topItems})),
}));
