import {create} from "zustand/react";
import {FieldValue, Timestamp} from "firebase/firestore";
import React from "react";

export interface UserProfile {
  inGameName: string;
  primaryWeapon: string;
  secondaryWeapon: string;
  gearScore: number;
  inGameRole: string[];
}

export interface GuildTrial {
  activatedAt: Timestamp | FieldValue;
  activatedBy: string;
  expiresAt: Timestamp;
  hasUsedTrial: boolean;
}

export interface Guild {
  isSubscribed: boolean;
  trial?: GuildTrial | null;
}

export interface UserDKPData {
  uid: string;
  inGameName: string;
  dkp: number;
  username: string;
  primaryWeapon: string;
  gearScore: number;
  secondaryWeapon: string;
}

export interface WeaponStats {
  [key: string]: number;
}

export interface WeaponCombo {
  primary: string;
  secondary: string;
  count: number;
  percentage: number;
  averageGearScore: number;
}

interface DashboardStoreValues {
  username: string | null;
  userProfile: UserProfile | null;
  guildData: Guild | null;
  guildId: string | null;
  userDKPList: UserDKPData[]
  averageGearScore: number;
  weaponStats: WeaponStats;
  topWeaponCombos: WeaponCombo[];
}

interface DashboardStoreActions {
  setCurrentUsername: (username: string) => void;
  setUserProfile: (userProfile: UserProfile) => void;
  setGuildData: (guildData: Guild) => void;
  setGuildId: (guildId: string) => void;
  setTrialData: (trialData: GuildTrial | null) => void;
  setUserDKPList: (userDKPList: UserDKPData[]) => void;
  setAverageGearScore: (averageGearScore: number) => void;
  setWeaponStats: (weaponStats: WeaponStats) => void;
  setTopWeaponCombos: (topWeaponCombos: WeaponCombo[]) => void;
}

export const useDashboardStore = create<DashboardStoreValues & DashboardStoreActions>((set, getState) => ({
  userProfile: null,
  username: null,
  guildData: null,
  guildId: null,
  userDKPList: [],
  averageGearScore: 0,
  weaponStats: {},
  topWeaponCombos: [],
  setCurrentUsername: (username: string) => {
    set({username});
  },
  setUserProfile: (userProfile: UserProfile) => {
    set({userProfile});
  },
  setGuildData: (guildData: Guild) => {
    set({guildData});
  },
  setGuildId: (guildId: string) => {
    set({guildId});
  },
  setTrialData: (trialData: GuildTrial | null) => {
    set({
      guildData: {
        isSubscribed: getState().guildData?.isSubscribed || false,
        trial: trialData,
      }
    });
  },
  setUserDKPList: (userDKPList: UserDKPData[]) => {
    set({userDKPList});
  },
  setAverageGearScore: (averageGearScore: number) => {
    set({averageGearScore});
  },
  setWeaponStats: (weaponStats: WeaponStats) => {
    set({weaponStats});
  },
  setTopWeaponCombos: (topWeaponCombos: WeaponCombo[]) => {
    set({topWeaponCombos});
  },
}));
