import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Provider } from 'react-redux';
import { Box } from '@mui/material';
import { store } from './store/store';

// Component imports
import Navigation from "./components/Navigation";
import Login from "./components/login/Login";
import Dashboard from "./components/Dashboard";
import SessionList from "./components/sessions/SessionList";
import SessionDetail from "./components/SessionDetail";
import NotFound from "./components/NotFound";
import AboutThisApp from "./components/AboutThisApp";
import PendingApproval from "./components/PendingApproval";
import Rejected from "./components/Rejected";
import DKPManagement from "./components/DKPManagement";
import UserManagement from "./components/UserManagement";
import UserDetails from "./components/UserDetails";
import SelectGuild from "./components/SelectGuild";
import Auth from "./components/Auth";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminLogs from './components/AdminLogs';
import UpdateNotification from './components/UpdateNotification';
import GuildInvite from './components/GuildInvite';
import Event from './components/events/Event';
import EventDetails from "./components/events/EventDetails";
import Wishlist from "./features/wishlist/components/Wishlist";
import EventRegistration from './components/events/EventRegistration';
import TeamAttendanceDashboard from "./components/teams/TeamAttendanceDashboard";
import TeamDetails from "./components/teams/TeamDetails";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Unsubscribe from './components/Unsubscribe';
import Home from './features/home/components/Home';
import useUser, { UserProvider } from "./hooks/useUser";
import ThemeCustomization from './themes';
import Locales from './components/Locales';
import ScrollTop from './components/ScrollTop';
import Snackbar from './components/@extended/Snackbar';
import Notistack from './components/third-party/Notistack';
import Footer from './components/Footer';
import TermsOfService from './components/TermsOfService';
import EmailOptInPopup from './components/EmailOptInPopup';
import UpgradeToPremium from "./components/UpgradeToPremium/UpgradeToPremium";
import './styles/globals.css';

// New component import
import DevKofiPayments from "./components/DevKofiPayments/DevKofiPayments";

const AppRoutes: React.FC = () => {
  const { user } = useUser();
  const location = useLocation();
  const restrictedRoutes = ["/login", "/about", "/discord-bot", "/home"];

  return (
    <Box sx={{ display: 'flex' }}>
      {!restrictedRoutes.includes(location.pathname) && <Navigation />}
      <Box component="main" sx={{
        flexGrow: 1,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}>
        {!restrictedRoutes.includes(location.pathname) && <UpdateNotification />}
        <Box sx={{ flex: 1 }}>
          <Routes>
            <Route path="/guild/:guildName" element={<GuildInvite />} />
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={user ? <Navigate to="/select-guild" /> : <Login />} />
            <Route path="/about" element={<AboutThisApp />} />
            <Route path="/pending-approval" element={<PendingApproval />} />
            <Route path="/rejected" element={<Rejected />} />
            <Route path="/select-guild" element={<SelectGuild />} />
            <Route path="/event-registration/:eventId" element={<EventRegistration />} />
            <Route path="/event-verification/:eventId" element={<EventRegistration />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/teams" element={<ProtectedRoute adminOrOfficer><TeamAttendanceDashboard /></ProtectedRoute>} />
            <Route path="/teams/:teamName" element={<ProtectedRoute adminOrOfficer><TeamDetails /></ProtectedRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/sessions" element={<ProtectedRoute><SessionList /></ProtectedRoute>} />
            <Route path="/session/:sessionId" element={<ProtectedRoute><SessionDetail /></ProtectedRoute>} />
            <Route path="/dkp-management" element={<ProtectedRoute adminOnly><DKPManagement /></ProtectedRoute>} />
            <Route path="/user-management" element={<ProtectedRoute adminOnly><UserManagement /></ProtectedRoute>} />
            <Route path="/admin-logs" element={<ProtectedRoute adminOnly><AdminLogs /></ProtectedRoute>} />
            <Route path="/user-details" element={<UserDetails />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/events" element={<ProtectedRoute><Event /></ProtectedRoute>} />
            <Route path="/event/:eventId" element={<ProtectedRoute><EventDetails /></ProtectedRoute>} />
            <Route path="/wishlist" element={<ProtectedRoute><Wishlist /></ProtectedRoute>} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/upgrade-to-premium" element={<UpgradeToPremium />} />

            {/* Developer-Only Route */}
            <Route
              path="/dev-kofi-payments"
              element={<ProtectedRoute developerOnly><DevKofiPayments /></ProtectedRoute>}
            />
          </Routes>
        </Box>
        <Footer />
      </Box>
      {user && <EmailOptInPopup uid={user.uid} />}
    </Box>
  );
};

const App: React.FC = () => (
  <Provider store={store}>
    <ThemeCustomization>
      <Locales>
        <ScrollTop>
          <UserProvider>
            <Notistack>
              <Router>
                <AppRoutes />
              </Router>
              <Snackbar />
            </Notistack>
          </UserProvider>
        </ScrollTop>
      </Locales>
    </ThemeCustomization>
  </Provider>
);

export default App;
