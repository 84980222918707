import * as React from 'react';
import {RiCloseFill} from "@remixicon/react";
import useModal from "../../../widgets/modal/Modal";  // Add this import



interface Testimonial {
  name: string;
  guild: string;
  shortContent: string;
  fullContent: {
    introduction: string;
    keyPoints: string[];
    conclusion: string;
  };
  image: string;
}

const testimonialData: Testimonial[] = [
  {
    name: 'Mr Isp',
    guild: 'Vice and Virtue',
    shortContent: "My guild 'Vice and Virtue' has been using Loot Manager at this stage for a few weeks now and it has completely transformed our loot distribution process.",
    fullContent: {
      introduction: "My guild 'Vice and Virtue' has been using Loot Manager for several weeks now, and it has completely transformed our loot distribution process.",
      keyPoints: [
        "Easy to use interface that requires minimal setup time",
        "Discord app integration helps track point allocation efficiently",
        "Streamlines loot allocation while ensuring fair rewards for active members",
        "Developer provides exceptional support with quick response times",
        "Swift bug fixes and regular updates keep the system running smoothly"
      ],
      conclusion: "If you're looking for a reliable DKP system that makes guild management a breeze, this is the one!"
    },
    image: '/images/newtheme/testimonial/mr_isp_banner.jpg',
  },
  {
    name: 'Marshadowo',
    guild: 'The House Stark',
    shortContent: "Our guild was searching for a reliable tool to manage loot distribution during raids, and Loot Manager has exceeded our expectations.",
    fullContent: {
      introduction: "The House Stark was searching for a reliable tool to manage loot distribution during raids, and Loot Manager has exceeded our expectations in every way.",
      keyPoints: [
        "Comprehensive system for managing raids, PvP, and other activities",
        "Flexible DKP system suitable for both casual and hardcore guilds",
        "Effortless setup process with intuitive feature integration",
        "Simplified gear wishlists and loot tracking",
        "Excellent Discord integration for real-time notifications",
        "Outstanding developer support and frequent updates"
      ],
      conclusion: "Just a few minutes of setup can save countless hours of organizing, making it a must-have for any growing or established guild looking to operate efficiently and boost member satisfaction."
    },
    image: '/images/newtheme/testimonial/marsh_banner.jpg',
  }
];

export default function Testimonials() {
  const [selectedTestimonial, setSelectedTestimonial] = React.useState<number | null>(null);
  const { open, close, Modal } = useModal();

  const handleOpenModal = (index: number) => {
    setSelectedTestimonial(index);
    open();
  };

  const handleCloseModal = () => {
    setSelectedTestimonial(null);
    close();
  };

  return (
    <div className="w-full bg-base-300 lg:px-20 lg:py-12 p-4">
      <div>
        <h2 className="skranji-bold text-4xl">What Guild Leaders Say</h2>
        <p className="text-lg pb-6">
          Testimonials are publicly available on our Discord server in the <span className="text-[#FBC755] cursor-pointer" onClick={() => window.open('https://discord.gg/uc2949Cdby', '_blank')}>#testimonials</span> channel
        </p>
        <div className="flex flex-col gap-4">
          {testimonialData.map((testimonial, index) => (
            <div key={testimonial.name} className="w-full p-4 bg-base-100 relative rounded-lg overflow-hidden">
              <img src={testimonial.image} className="absolute lg:block hidden right-0 top-0 h-full w-96 object-cover" />
              <div className="absolute right-0 top-0 h-full w-96 lg:block hidden bg-gradient-to-r from-base-100 to-transparent" />
              <div className="text-lg font-semibold text-[#FBC755]">{testimonial.name}</div>
              <div className="text-sm text-secondary-content">{testimonial.guild}</div>
              <div className="text-lg lg:w-[calc(100%-384px)]">{testimonial.shortContent}</div>
              <button className="btn btn-outline btn-sm rounded mt-2" onClick={() => handleOpenModal(index)}>read detailed review</button>
            </div>
          ))}
        </div>
      </div>

      <Modal>
        {selectedTestimonial !== null && (
          <div className="bg-base-100 px-8 py-6 rounded-lg container mx-auto">
            <div className="flex flex-row justify-between items-start">
              <h3 className="skranji-bold text-2xl text-[#FBC755]">
                {testimonialData[selectedTestimonial].name} - {testimonialData[selectedTestimonial].guild}
              </h3>
              <RiCloseFill className="cursor-pointer hover:text-[#FBC755] text-2xl" onClick={handleCloseModal}/>
            </div>
            <div>
              <div className="text-lg pt-4">
                {testimonialData[selectedTestimonial].fullContent.introduction}
              </div>
              <ul className="list-disc list-inside py-4 text-lg">
                {testimonialData[selectedTestimonial].fullContent.keyPoints.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
              <div className="text-[#FBC755] text-lg font-bold">
                {testimonialData[selectedTestimonial].fullContent.conclusion}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}