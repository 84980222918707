import useUser from "../../hooks/useUser";
import {ReactNode} from "react";


function AdminComponent({ children }: { children?: ReactNode }) {
  const { selectedGuild, user } = useUser();
  const userRole = user?.memberships.find(
    (m) => m.guildId === selectedGuild
  )?.role;
  const isAdmin = userRole === "admin";

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
}

export {
  AdminComponent as default,
};
