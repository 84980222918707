import TutorialPopover from "../../../components/common/TutorialPopover";
import AdminComponent from "../../../shared/providers/AdminComponent";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import React from "react";
import {lockAllWishlists, resetWishlistTokens, unlockAllWishlists} from "../../../components/Wishlist/WishListServices";
import {enqueueSnackbar} from "notistack";
import useUser from "../../../hooks/useUser";
import {useSticky} from "../../../shared/hooks/useSticky";
import {useWishlistStore} from "../store";


function WishlistHeader() {
  const { selectedGuild } = useUser();
  const isSticky = useSticky(5);
  const isLocked = useWishlistStore((store) => store.isLocked);
  const showTopItems = useWishlistStore((store) => store.showTopItems);
  const setShowTopItems = useWishlistStore((store) => store.setShowTopItems);

  const handleSwitchTopItems = () => {
    setShowTopItems(!showTopItems);
  }

  const handleLockAllWishlists = async () => {
    if (!selectedGuild) return;
    await lockAllWishlists(selectedGuild);
  };

  const handleUnlockAllWishlists = async () => {
    if (!selectedGuild) return;
    await unlockAllWishlists(selectedGuild);
  };

  const handleResetTokens = async () => {
    if (!selectedGuild) return;
    await resetWishlistTokens(selectedGuild);
    enqueueSnackbar("Wishlist tokens have been reset for all users.", { variant: "success" });
  };


  return (
    <div
      className={`bg-opacity-25 z-50 sticky md:top-0 top-14 w-full bg-cover bg-center bg-no-repeat transition-all ${isSticky ? "h-16" : "h-48"}`}
      style={{backgroundImage: 'url(/images/newsite/bkg.jpg)'}}
    >
      <div className="w-full h-full bg-black bg-opacity-50 flex justify-center items-end">
        <div
          className="container mx-auto max-w-6xl pb-4 px-4 flex flex-row justify-between items-center w-full">
          <h3 className="font-bold text-xl flex flex-row items-center gap-2">
            Wishlist
            <TutorialPopover
              title="Your Wishlist"
              steps={[{
                title: "Items in your wishlist have priority during loot distribution."
              }]}
            />
          </h3>
          <div className="flex md:flex-row flex-col md:items-center items-end gap-2">
            {!isLocked && (
              <AdminComponent>
                <button className="btn-warning btn btn-sm" onClick={handleLockAllWishlists}><LockIcon/>Lock All
                </button>
              </AdminComponent>
            )}
            {isLocked && (
              <AdminComponent>
                <button className="btn-success btn btn-sm" onClick={handleUnlockAllWishlists}><LockOpenIcon/>Unlock
                  All
                </button>
              </AdminComponent>
            )}
            <AdminComponent>
              <button className="btn-outline btn btn-sm" onClick={handleResetTokens}>Reset Tokens</button>
            </AdminComponent>
            <button className="btn-outline btn btn-sm" onClick={handleSwitchTopItems}>
              {showTopItems ? "Hide Top 10 Popular Items" : "Show Top 10 Popular Items"}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  WishlistHeader as default,
}
