import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { useNavigate } from "react-router-dom";

export default function CardAlert() {
  const navigate = useNavigate();

  const handleUpgradeClick = () => {
    navigate("/upgrade-to-premium");
  };

  return (
    <Card variant="outlined" sx={{ m: 1.5, p: 1.5 }}>
      <CardContent>
        <AutoAwesomeRoundedIcon fontSize="small" />
        <Typography gutterBottom sx={{ fontWeight: 600 }}>
          Unlock Unlimited Features
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, color: "text.secondary" }}>
          Get access to premium features, including unlimited access and Discord integration.
        </Typography>
        <Button 
          variant="contained" 
          size="small" 
          fullWidth 
          onClick={handleUpgradeClick}
        >
          Learn More & Upgrade
        </Button>
      </CardContent>
    </Card>
  );
}
