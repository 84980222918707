// src/components/Features.tsx
import * as React from 'react';
import {RiCalendarEventFill, RiDashboardFill, RiGroupFill, RiNumbersLine, RiStarFill} from "@remixicon/react";


const features = [
  {
    title: 'Event Management',
    icon: <RiCalendarEventFill />,
    gradient: 'from-transparent to-green-900 text-green-300',
    items: [
      'Event creation and scheduling',
      'Attendance verification',
      'Participation tracking',
      'Team assignments',
    ],
  },
  {
    title: 'User Management',
    icon: <RiGroupFill />,
    gradient: 'from-transparent to-cyan-900 text-cyan-300',
    items: [
      'Role hierarchy',
      'Character profiles',
      'Gear score tracking',
      'Weapon specializations',
    ],
  },
  {
    title: 'DKP Bidding System',
    icon: <RiNumbersLine />,
    gradient: 'from-transparent to-rose-900 text-rose-300',
    items: [
      'DKP tracking and distribution',
      'Automatic DKP calculations',
      'Split or distribute DKP options',
      'Historical DKP logs',
    ],
  },
  {
    title: 'Wishlist System',
    icon: <RiStarFill />,
    gradient: 'from-transparent to-amber-900 text-amber-300',
    items: [
      'Token-based wishlist management',
      'Weekly token resets',
      'Priority system',
      'Wishlist tracking',
    ],
  },
  {
    title: 'Analytics',
    icon: <RiDashboardFill />,
    gradient: 'from-transparent to-slate-700 text-slate-300',
    items: [
      'Team composition analysis',
      'Weapon distribution stats',
      'Average gear score tracking',
    ],
  },
];

export default function Features() {
  return (
    <div className="w-full bg-base-200 lg:px-20 lg:py-12 p-4">
      <h2 className="skranji-bold text-4xl mb-4">Features</h2>
      <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 gap-4">
        {features.map((feature, index) => (
          <div key={index} className={`card bg-base-100 p-4 rounded-lg h-full bg-gradient-to-br to-95% ${feature.gradient}`}>
            <h3 className="text-lg font-semibold flex flex-row items-center gap-2">{feature.icon}{feature.title}</h3>
            <div className="flex flex-col gap-2 mt-4 text-primary-content text-lg list-disc list list-inside">
              {feature.items.map((item, i) => (
                <p className="list-item" key={i}>
                  {item}
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
