import React, {useEffect} from 'react';
import {Tooltip} from '@mui/material';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { db } from '../firebase';
import useUser from '../hooks/useUser';
import {useDashboardStore, UserDKPData, WeaponStats} from "../features/dashboard/store";
import {RiInformationFill} from "@remixicon/react";
import {toPlural} from "../shared/utils/toPlural";


const TABS = {
    DKP: 0,
    GUILD_STATS: 1,
    SESSIONS: 2,
    EVENTS: 3,
}

const DKPRanking = () => {
    const userDKPList = useDashboardStore((state) => state.userDKPList);

    return (
      <div className="overflow-x-auto">
          <table className="table table-zebra">
              <thead>
                  <tr>
                      <th>Rank</th>
                      <th>In-Game Name</th>
                      <th>Weapons</th>
                      <th>Gear Score</th>
                      <th>DKP</th>
                  </tr>
              </thead>
              <tbody>
                  {userDKPList.map((user, index) => (
                    <tr key={user.uid}>
                        <td>{index + 1}</td>
                        <td>{user.inGameName}</td>
                        <td>
                            {user.primaryWeapon} {user.secondaryWeapon && `+ ${user.secondaryWeapon}`}
                        </td>
                        <td>
                            <span className="text-xl font-bold font-mono">{user.gearScore}</span>
                        </td>
                        <td>
                            <span className="text-xl font-bold font-mono">{Math.ceil(user.dkp)}</span>
                        </td>
                    </tr>
                  ))}
              </tbody>
          </table>
      </div>
    )
}

const GuildStats = () => {
    const userDKPListLength = useDashboardStore((state) => state.userDKPList.length);
    const topWeaponCombos = useDashboardStore((state) => state.topWeaponCombos);
    const weaponStats = useDashboardStore((state) => state.weaponStats);
    const averageGearScore = useDashboardStore((state) => state.averageGearScore);

    return (
      <div className="flex flex-col gap-4">
          <div>
              <p className="text-lg mb-2 font-semibold">Weapon Type Distribution</p>
              <div className="bg-base-200 p-4 rounded flex flex-col gap-2">
                  {Object.entries(weaponStats)
                    .sort(([, a], [, b]) => b - a)
                    .map(([weapon, percentage]) => (
                      <div key={weapon} className="relative h-12 border border-base-300">
                          <div className="flex flex-row gap-4 items-center h-full w-full justify-between px-4">
                              <p className="text-lg font-semibold">{weapon}</p>
                              <p className="font-mono font-bold text-xl">{percentage}%</p>
                          </div>
                          <div className="bg-primary h-1 absolute bottom-0 left-0" style={{width: `${percentage}%`}}/>
                      </div>
                    ))}
              </div>
          </div>
          <div>
              <p className="text-lg mb-2 font-semibold">Popular Weapon Combinations</p>
              <div className="bg-base-200 p-4 rounded flex flex-col gap-2">
                  {topWeaponCombos.map((combo, index) => (
                    <div key={index} className="relative h-12 border border-base-300">
                        <div className="flex flex-row gap-4 items-center h-full w-full justify-between px-4">
                            <p className="text-lg font-semibold flex flex-row items-center gap-2">
                               <span>{index + 1}. {combo.primary} + {combo.secondary}</span>
                                <Tooltip title="Avarage Gear Score">
                                    <span className="bg-amber-800 text-amber-300 px-2 rounded-md flex flex-row items-center gap-1 cursor-pointer">
                                        {combo.averageGearScore}
                                        <RiInformationFill size={16} />
                                    </span>
                                </Tooltip>
                            </p>
                            <p className="font-mono font-bold text-xl">{combo.count} {toPlural('member', combo.count)} • {combo.percentage}%</p>
                        </div>
                        <div className="bg-success h-1 absolute bottom-0 left-0"
                             style={{width: `${combo.percentage}%`}}/>
                    </div>
                  ))}
              </div>
          </div>
          <div>
              <p className="text-lg mb-2 font-semibold">Combat Statistics</p>
              <div className="bg-base-200 p-4 rounded gap-2 grid grid-cols-2">
                  <div>
                      <p className="text-secondary-content font-semibold">Average Gear Score</p>
                      <p className="font-mono font-bold text-xl">{averageGearScore}</p>
                  </div>
                  <div>
                      <p className="text-secondary-content font-semibold">Total Members</p>
                      <p className="font-mono font-bold text-xl">{userDKPListLength}</p>
                  </div>
              </div>
          </div>
      </div>
    )
}

const UserDKPList: React.FC = () => {
    const {selectedGuild} = useUser();
    const [tabValue, setTabValue] = React.useState(TABS.DKP);
    const setUserDKPList = useDashboardStore((state) => state.setUserDKPList);
    const setWeaponStats = useDashboardStore((state) => state.setWeaponStats);
    const setAverageGearScore = useDashboardStore((state) => state.setAverageGearScore);
    const setTopWeaponCombos = useDashboardStore((state) => state.setTopWeaponCombos);


    useEffect(() => {
        const fetchUserDKP = async () => {
            if (!selectedGuild) return;

            const userGuildsRef = collection(db, 'userGuilds');
            const q = query(userGuildsRef, where('guildId', '==', selectedGuild));
            const querySnapshot = await getDocs(q);

            const dkpData: UserDKPData[] = [];
            const weaponCounts: WeaponStats = {};
            const comboCounts: Record<string, number> = {};
            const comboGearScores: Record<string, number[]> = {};

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                dkpData.push({
                    uid: data.uid,
                    inGameName: data.inGameName || 'N/A',
                    dkp: data.dkp || 0,
                    username: data.username || 'N/A',
                    primaryWeapon: data.primaryWeapon || 'N/A',
                    secondaryWeapon: data.secondaryWeapon || 'N/A',
                    gearScore: data.gearScore || 0
                });

                if (data.primaryWeapon) {
                    weaponCounts[data.primaryWeapon] = (weaponCounts[data.primaryWeapon] || 0) + 1;
                }
                if (data.secondaryWeapon) {
                    weaponCounts[data.secondaryWeapon] = (weaponCounts[data.secondaryWeapon] || 0) + 1;
                }

                if (data.primaryWeapon && data.secondaryWeapon) {
                    const comboKey = `${data.primaryWeapon} + ${data.secondaryWeapon}`;
                    comboCounts[comboKey] = (comboCounts[comboKey] || 0) + 1;

                    if (!comboGearScores[comboKey]) {
                        comboGearScores[comboKey] = [];
                    }
                    comboGearScores[comboKey].push(data.gearScore || 0);
                }
            });

            const totalWeapons = Object.values(weaponCounts).reduce((a, b) => a + b, 0);
            Object.keys(weaponCounts).forEach(weapon => {
                weaponCounts[weapon] = Number(((weaponCounts[weapon] / totalWeapons) * 100).toFixed(1));
            });

            const sortedCombos = Object.entries(comboCounts)
              .map(([combo, count]) => {
                  const gearScores = comboGearScores[combo] || [];
                  const validGearScores = gearScores.filter(score => score > 0);
                  const averageGearScore = validGearScores.length > 0
                    ? Math.round(validGearScores.reduce((a, b) => a + b, 0) / validGearScores.length)
                    : 0;

                  return {
                      primary: combo.split(' + ')[0],
                      secondary: combo.split(' + ')[1],
                      count,
                      percentage: Number(((count / dkpData.length) * 100).toFixed(1)),
                      averageGearScore
                  };
              })
              .sort((a, b) => b.count - a.count)
              .slice(0, 5);

            const validGearScores = dkpData.filter(user => user.gearScore > 0);
            const totalGearScore = validGearScores.reduce((sum, user) => sum + user.gearScore, 0);
            const averageGS = validGearScores.length > 0
              ? Math.round(totalGearScore / validGearScores.length)
              : 0;

            dkpData.sort((a, b) => b.dkp - a.dkp);
            setUserDKPList(dkpData);
            setWeaponStats(weaponCounts);
            setAverageGearScore(averageGS);
            setTopWeaponCombos(sortedCombos);
        };

        fetchUserDKP();
    }, [selectedGuild]);

    return (
        <div className="bg-base-300 p-4">
            <div role="tablist" className="tabs tabs-boxed mb-4">
                <a role="tab" className={`tab ${tabValue === TABS.DKP && 'tab-active'}`} onClick={() => setTabValue(TABS.DKP)}>DKP Ranking</a>
                <a role="tab" className={`tab ${tabValue === TABS.GUILD_STATS && 'tab-active'}`} onClick={() => setTabValue(TABS.GUILD_STATS)}>Guild Statistics</a>
                <a role="tab" className={`tab ${tabValue === TABS.SESSIONS && 'tab-active'}`}>Active sessions <span className="ml-2 badge badge-sm badge-info rounded">WIP</span></a>
                <a role="tab" className={`tab ${tabValue === TABS.EVENTS && 'tab-active'}`}>Events <span
                  className="ml-2 badge badge-sm badge-info rounded">WIP</span></a>
            </div>

            {tabValue === TABS.DKP && <DKPRanking />}
            {tabValue === TABS.GUILD_STATS && <GuildStats />}
        </div>
    );

};

export default UserDKPList;
