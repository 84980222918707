import React from "react";


function WishlistLoader() {

  return (
    <div className="flex absolute top-0 left-0 w-full h-full justify-center items-center bg-base-200/50 z-40">
      <h5 className="text-white">Loading...</h5>
    </div>
  )
}

export {
  WishlistLoader as default,
}
