// src/shared-theme/AppTheme.tsx
import * as React from 'react';
import { ThemeProvider, createTheme, ThemeOptions, Theme } from '@mui/material/styles';
import type { Components } from '@mui/material/styles/components';
import { ContainerProps } from '@mui/material/Container';
import { inputsCustomizations } from './customizations/inputs';
import { dataDisplayCustomizations } from './customizations/dataDisplay';
import { feedbackCustomizations } from './customizations/feedback';
import { navigationCustomizations } from './customizations/navigation';
import { surfacesCustomizations } from './customizations/surfaces';
import { typography, shadows, shape } from './themePrimitives';

interface AppThemeProps {
  children: React.ReactNode;
  disableCustomTheme?: boolean;
  themeComponents?: ThemeOptions['components'];
}

export default function AppTheme(props: AppThemeProps) {
  const { children, disableCustomTheme, themeComponents } = props;

  React.useEffect(() => {
    document.documentElement.setAttribute('data-mui-color-scheme', 'dark');
    localStorage.setItem('mui-mode', 'dark');
  }, []);

  const theme = React.useMemo<Theme>(() => {
    if (disableCustomTheme) {
      // Return a minimal default theme to avoid type issues
      return createTheme();
    }
    return createTheme({
      cssVariables: {
        colorSchemeSelector: 'data-mui-color-scheme',
        cssVarPrefix: 'template',
      },
      palette: {
        mode: 'dark',
        background: {
          default: '#121212',
          paper: '#1E1E1E',
        },
        primary: {
          main: '#5865F2',
        },
      },
      typography,
      shadows,
      shape,
      components: {
        ...inputsCustomizations,
        ...dataDisplayCustomizations,
        ...feedbackCustomizations,
        ...navigationCustomizations,
        ...surfacesCustomizations,
        // Explicitly type the callback param for styleOverrides
        MuiContainer: {
          styleOverrides: {
            root: ({ theme }: { theme: Theme; ownerState: ContainerProps }) => ({
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
            }),
          },
        },
        ...themeComponents,
      } as Components,
    });
  }, [disableCustomTheme, themeComponents]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
