import TutorialPopover from "../../../components/common/TutorialPopover";
import React from "react";
import {useWishlistStore} from "../store";


function WishlistTokens() {
  const tokenBalance = useWishlistStore(state => state.tokenBalance);

  return (
    <p className="flex flex-row items-center gap-2 text-secondary-content">
      Tokens <span className="badge badge-ghost p-4 rounded-lg text-lg font-bold font-mono">{tokenBalance}</span>
      <TutorialPopover
        title="Wishlist Tokens"
        steps={[{
          title: "Each token allows you to add one item to your wishlist. Tokens are awarded by guild administrators"
        }]}
      />
    </p>
  )
}

export {
  WishlistTokens as default,
}
