import React, {useEffect, useState} from "react";
import {fetchTopWishlistedItems} from "../../../components/Wishlist/WishListServices";
import useUser from "../../../hooks/useUser";
import {useWishlistStore} from "../store";


function WishlistTopItems() {
  const { selectedGuild } = useUser();
  const topItems = useWishlistStore((state) => state.topItems);
  const setTopItems = useWishlistStore((state) => state.setTopItems);
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchTopItems = async () => {
    if (!selectedGuild) return;
    setIsLoading(true);
    const items = await fetchTopWishlistedItems(selectedGuild);
    setTopItems(items);
    setIsLoading(false);
  };

  useEffect(() => {
    if (topItems.length === 0) {
      handleFetchTopItems();
    }
  }, [topItems]);

  return (
    <div className="w-full bg-base-300 p-4"
    >
      <h6 className="text-lg mb-2">
        Top 10 Most Wanted Items
      </h6>
      <div className="flex flex-col gap-2">
        {isLoading && (
          <div className="w-full h-60 flex justify-center items-center">
            <div className="loading"></div>
          </div>
        )}
        {topItems.map((item, index) => (
          <div className="w-full" key={item.itemName}>
            <div className="card flex flex-row justify-between items-center p-4 bg-base-200 rounded-md">
              <p className="text-lg font-bold">{`${index + 1}. ${item.itemName}`}</p>
              <div className="text-4xl font-bold text-center font-mono">{item.count}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export {
  WishlistTopItems as default,
}
