// src/components/Wishlist/Wishlist.tsx

import React from "react";
import { WishlistPanel } from "./WishlistPanel";
import {useWishlistStore} from "../store";
import ItemSearch from "../../../widgets/item-search/ItemSearch";
import WishlistTopItems from "./WishlistTopItems";
import WishlistHeader from "./WishlistHeader";


const Wishlist = () => {
  const showTopItems = useWishlistStore((store) => store.showTopItems);
  const searchQuery = useWishlistStore((store) => store.searchQuery);
  const setSearchQuery = useWishlistStore((store) => store.setSearchQuery);

  return (
    <div className="relative">
      <WishlistHeader />
      <div className="container mx-auto max-w-6xl px-4 py-6 md:mt-0 mt-14 flex flex-col gap-4">
        <ItemSearch setQuery={setSearchQuery} query={searchQuery}/>
        {showTopItems && <WishlistTopItems />}
        <WishlistPanel/>
      </div>
    </div>
  );

};

export default Wishlist;
