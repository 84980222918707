// src/components/Hero.tsx
import * as React from 'react';
import {RiArrowDownFill, RiSwordFill} from "@remixicon/react";
import useUser from "../../../hooks/useUser";
import {useNavigate} from "react-router-dom";


export default function Hero() {
  const navigate = useNavigate();
  const { user } = useUser();

  const handleLogin = () => {
    if (user) {
      navigate("/dashboard");
      return;
    }
    navigate("/login");
  }

  return (
    <div className="w-full h-screen relative">
      <img
        className="w-full h-full object-cover blur-sm absolute left-0 top-0"
        src="/images/newsite/chest_bg.png"
        alt="LootManager Logo"
      />
      <div className="w-full h-full absolute left-0 top-0 bg-base-100/40">
        <div className="xl:w-1/2 w-full h-full flex flex-col justify-center items-start lg:px-20 px-4">
          <img src="/images/newsite/chest.png" className="w-60" alt="LootManager"/>
          <h1 className="md:text-5xl text-3xl leading-tight uppercase skranji-bold text-start">Optimize your guild,<br/> achieve
            progression!</h1>
          <p className="text-mono text-lg my-4">
            Transform your guild operations with our powerful management suite. From advanced
            analytics and team optimization to seamless loot distribution, get the tools you need
            to drive progression. Join{' '}
            <span style={{color: '#FBC755', fontWeight: 600}}>3500+</span>{' '}
            users making data-driven decisions while keeping their raiders engaged through transparent
            DKP tracking and dynamic wishlist systems.
          </p>
          <div className="grid grid-cols-2 w-full gap-4">
            <button onClick={handleLogin} className="btn btn-lg flex-nowrap btn-ghost bg-[#FBC755] hover:brightness-95 hover:bg-[#FBC755] text-white w-full">
              <RiSwordFill />
              {user ? 'Go to Dashboard' : 'Login'}
            </button>
            <button className="btn btn-lg btn-outline w-full flex-nowrap" onClick={() => window.scrollTo({ top: window.innerHeight, behavior:'smooth' })}><RiArrowDownFill /> Learn More</button>
          </div>
        </div>
      </div>
    </div>
  );
}
