// src/components/Dashboard.tsx

import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import {
  doc,
  getDoc,
} from "firebase/firestore";
import useUser from "../hooks/useUser";
import { Timestamp } from "firebase/firestore";
import UserDKPList from "../DKPManagement/UserDKPList";
import AppTheme from "../shared-theme/AppTheme";
import {Guild, GuildTrial, useDashboardStore} from "../features/dashboard/store";
import Profile from "../features/dashboard/components/Profile";
import DashboardHeader from "../features/dashboard/components/DashboardHeader";
import DKP from "../features/dashboard/components/dkp";


interface UserGuild {
  inGameName?: string;
  primaryWeapon?: string;
  secondaryWeapon?: string;
  gearScore?: number;
  inGameRole?: string[];
  username?: string;
}

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, loading, selectedGuild } = useUser();
  const [authenticating, setAuthenticating] = useState<boolean>(false);

  const setUserProfile = useDashboardStore((store) => store.setUserProfile);
  const setCurrentUsername = useDashboardStore((store) => store.setCurrentUsername);
  const setGuildData = useDashboardStore((store) => store.setGuildData);
  const setTrialData = useDashboardStore((store) => store.setTrialData);
  const setGuildId = useDashboardStore((store) => store.setGuildId);


  // Token check effect
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    if (token) {
      setAuthenticating(true);
      navigate("/dashboard", { replace: true });
    }
  }, [location, navigate]);

  // TODO: refactor this
  // Main data loading effect
  useEffect(() => {
    if (!user || !selectedGuild) return;

    const loadDashboardData = async () => {
      try {
        // Directly access the userGuild document using its ID
        const userGuildDocRef = doc(db, "userGuilds", `${user.uid}_${selectedGuild}`);
        const userGuildDoc = await getDoc(userGuildDocRef);

        if (userGuildDoc.exists()) {
          const userGuildData = userGuildDoc.data() as UserGuild;

          // Set user guild ID
          setGuildId(userGuildDoc.id);

          // Set username
          setCurrentUsername(userGuildData.username || "Unknown User");

          // Set profile data
          const profileData = {
            inGameName: userGuildData.inGameName || "",
            primaryWeapon: userGuildData.primaryWeapon || "",
            secondaryWeapon: userGuildData.secondaryWeapon || "",
            gearScore: userGuildData.gearScore || 0,
            inGameRole: userGuildData.inGameRole || [],
          };

          setUserProfile(profileData);
          // Check if profile needs completion
          if (
            !userGuildData.inGameName?.trim() ||
            !userGuildData.primaryWeapon?.trim() ||
            !userGuildData.gearScore ||
            !userGuildData.inGameRole?.length
          ) {
            // setShowInGameNameModal(true);
          }
        } else {
          console.error("UserGuild document does not exist.");
          // Handle the case where the document doesn't exist
        }

        // Fetch trial status
        const guildDoc = await getDoc(doc(db, "guilds", selectedGuild));
        const trialData = guildDoc.data()?.trial as GuildTrial | undefined;
        const currentTime = Timestamp.now();

        if (trialData && trialData.expiresAt.toDate() > currentTime.toDate()) {
          setTrialData(trialData);
        } else {
          setTrialData(null);
        }
      } catch (error) {
        console.error("Error loading dashboard data:", error);
      }
    };

    loadDashboardData();
  }, [user, selectedGuild]);

  useEffect(() => {
    const fetchGuildData = async () => {
      if (!selectedGuild) return;

      try {
        const guildDoc = await getDoc(doc(db, "guilds", selectedGuild));
        const guildDocData = guildDoc.data() as Guild;
        setGuildData(guildDocData);
      } catch (error) {
        console.error("Error fetching guild data:", error);
      }
    };

    fetchGuildData();
  }, [selectedGuild]);

  // Function to fetch the user's profile
  const fetchUserProfile = async () => {
    if (!user || !selectedGuild) return;

    try {
      // Directly access the userGuild document using its ID
      const userGuildDocRef = doc(db, "userGuilds", `${user.uid}_${selectedGuild}`);
      const userGuildDoc = await getDoc(userGuildDocRef);

      if (userGuildDoc.exists()) {
        const userGuildData = userGuildDoc.data() as UserGuild;

        setGuildId(userGuildDoc.id);

        const profileData = {
          inGameName: userGuildData.inGameName || "",
          primaryWeapon: userGuildData.primaryWeapon || "",
          secondaryWeapon: userGuildData.secondaryWeapon || "",
          gearScore: userGuildData.gearScore || 0,
          inGameRole: userGuildData.inGameRole || [],
        };

        setUserProfile(profileData);
      } else {
        console.error("UserGuild document does not exist.");
        // Handle the case where the document doesn't exist
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    if (user && selectedGuild) {
      fetchUserProfile();
    }
  }, [user, selectedGuild]);

  if (loading || authenticating) {
    return <div className="loading">Loading...</div>;
  }

  const isApproved = user?.status === "approved";

  if (!isApproved) {
    navigate("/pending-approval");
    return null;
  }

  return (
    <AppTheme>
      <div>
        <DashboardHeader/>
        <div className="container mx-auto max-w-6xl px-4 py-6 md:mt-0 mt-14 flex flex-col gap-4">
          <DKP />
          <Profile />
          <UserDKPList />
        </div>
      </div>
    </AppTheme>
  );
};

export default Dashboard;
