import React, { useState } from 'react';
import {
  Box,
  Typography,
  Stack,
  Popover,
  Modal,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useDispatch } from 'react-redux';
import { disableTutorials } from '../../store/tutorialSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface TutorialStep {
  title: string;
  image?: string;
}

interface TutorialPopoverProps {
  title: string;
  steps: TutorialStep[];
}

const TutorialPopover: React.FC<TutorialPopoverProps> = ({ title, steps }) => {
  const dispatch = useDispatch();
  const isTutorialEnabled = useSelector((state: RootState) => state.tutorial.isEnabled);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleHelpOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHelpClose = () => setAnchorEl(null);

  const handleDisableClick = () => {
    setShowConfirmDialog(true);
  };

  const handleConfirmDisable = () => {
    dispatch(disableTutorials());
    setShowConfirmDialog(false);
    handleHelpClose();
  };

  if (!isTutorialEnabled) {
    return null;
  }

  return (
    <>
      <button onClick={handleHelpOpen} className="btn btn-outline btn-sm px-2 rounded-lg border-none bg-secondary text-orange-500 hover:bg-orange-500">
        <HelpOutlineIcon />
      </button>

      <Popover
        sx={{
          '& .MuiPopover-paper': {
            maxHeight: '80vh',
            maxWidth: '80vw',
            overflow: 'auto',
            background: 'rgba(35, 35, 35, 0.95)',
            border: '2px solid #FFA500',
            borderRadius: 2
          }
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleHelpClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        container={document.body}
        marginThreshold={16}
      >
        <Box sx={{ p: 3, maxWidth: 600 }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: '#FFA500',
              borderBottom: '2px solid rgba(255, 165, 0, 0.3)',
              pb: 1,
              mb: 2
            }}
          >
            {title}
          </Typography>

          <Stack spacing={3}>
            {steps.map((step, index) => (
              <Box
                key={index}
                sx={{
                  background: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: 1,
                  p: 2,
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    background: 'rgba(255, 255, 255, 0.08)',
                    transform: 'translateX(4px)'
                  }
                }}
              >
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  {steps.length > 1 && (
                    <Box
                      sx={{
                        minWidth: 24,
                        height: 24,
                        borderRadius: '50%',
                        background: 'rgba(255, 165, 0, 0.2)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#FFA500',
                        fontWeight: 'bold'
                      }}
                    >
                      {index + 1}
                    </Box>
                  )}
                  {step.title}
                </Typography>

                {step.image && (
                  <img
                    src={step.image}
                    alt={step.title}
                    style={{
                      width: '100%',
                      borderRadius: 8,
                      cursor: 'pointer',
                      marginTop: 8,
                      border: '1px solid rgba(255, 165, 0, 0.2)',
                      transition: 'all 0.2s ease'
                    }}
                    onClick={() => step.image && setSelectedImage(step.image)}
                  />
                )}
              </Box>
            ))}
          </Stack>

          <Box sx={{
            mt: 3,
            pt: 2,
            borderTop: '1px solid rgba(255, 165, 0, 0.2)',
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Button
              variant="contained"
              size="medium"
              onClick={handleDisableClick}
              sx={{
                color: 'white',
                backgroundColor: 'rgba(255, 165, 0, 0.7)',
                '&:hover': {
                  backgroundColor: '#FFA500',
                }
              }}
            >
              Hide All Tutorial Icons (Can be restored in Dashboard)
            </Button>
          </Box>
        </Box>
      </Popover>

      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ color: '#FFA500' }}>
          Disable Tutorial Icons
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            You can restore the tutorial icons anytime from your dashboard:
          </Typography>
          <Box sx={{ mt: 2, mb: 2 }}>
            <img
              src="/images/tutorial/popover/restore_popover.png"
              alt="How to restore tutorials"
              style={{
                width: '100%',
                borderRadius: 8,
                border: '1px solid rgba(255, 165, 0, 0.2)'
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDisable}
            variant="contained"
            sx={{
              backgroundColor: 'rgba(255, 165, 0, 0.7)',
              '&:hover': { backgroundColor: '#FFA500' }
            }}
          >
            Confirm Disable
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          component="img"
          src={selectedImage || ''}
          sx={{
            maxWidth: '90vw',
            maxHeight: '90vh',
            objectFit: 'contain',
            bgcolor: 'background.paper',
            borderRadius: 1,
            cursor: 'pointer',
          }}
          onClick={() => setSelectedImage(null)}
        />
      </Modal>
    </>
  );
};

export default TutorialPopover;
