import React from "react";
import {DownOutlined} from "@ant-design/icons";


function ItemSearch({ query, setQuery }: {
  query: string;
  setQuery: (query: string) => void;
}) {

  return (
    <div className="flex flex-row justify-between items-center gap-2">
      <input
        type="text"
        placeholder="Search for items..."
        className="input input-bordered w-full"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <div className="dropdown w-64">
        <button className="btn btn-primary w-full">Throne and Liberty <DownOutlined/></button>
        <div tabIndex={0} className="dropdown-content z-50 w-64 menu p-0 bg-base-100 shadow">
          <button className="btn btn-active">Throne and Liberty</button>
          <button className="btn btn-primary">Other <div className="badge badge-info rounded">WIP</div></button>
        </div>
      </div>
    </div>
  )
}

export {
  ItemSearch as default,
}
