import { motion } from "framer-motion";
import {ReactNode, useState} from "react";
import {createPortal} from "react-dom";

function useModal() {
  const [isOpen, setIsOpen] = useState(false);

  return {
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    Modal: ({ children }: { children: ReactNode }) => (
      createPortal((
          <div className={`modal ${isOpen && 'modal-open'}`}>
            <motion.div
              initial={{opacity: 0, y: -100}}
              animate={{opacity: isOpen ? 1 : 0, y: isOpen ? 0 : 100}}
              exit={{opacity: 0, y: 100}}
              transition={{duration: 0.2}}
            >
              {children}
            </motion.div>
          </div>
        ), document.body)
    ),
  }
}

export {
  useModal as default,
}
