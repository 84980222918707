// src/MarketingPage.tsx
import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Hero from './Hero';
import Pricing from './Pricing';
import Features from './Features';
import Testimonials from './Testimonials';
import FAQ from './FAQ';

const newTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
    primary: {
      main: '#5865F2',
    },
  },
});

export default function MarketingPage() {
  return (
    <ThemeProvider theme={newTheme}>
      <CssBaseline enableColorScheme />
      <Hero />
      <div>
        <Features />
        <Testimonials />
        <Pricing />
        <FAQ />
      </div>
    </ThemeProvider>
  );
}
