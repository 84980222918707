// src/components/Wishlist/WishlistPanel.tsx

import React, { useEffect } from "react";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from "../../../firebase";
import useUser from "../../../hooks/useUser";
import {useWishlistStore} from "../store";
import WishlistItemsList from "./WishlistAllItemsList";
import WishlistSavedItemsList from "./WishlistSavedItemsList";
import WishlistLock from "./WishlistLock";
import WishlistLoader from "./WishlistLoader";
import WishlistTokens from "./WishlistTokens";


export const WishlistPanel: React.FC = () => {
  const { user, selectedGuild } = useUser();
  const isLocked = useWishlistStore((store) => store.isLocked);
  const setIsLocked = useWishlistStore((store) => store.setIsLocked);
  const setItems = useWishlistStore((store) => store.setItems);
  const isLoading = useWishlistStore((store) => store.isLoading);
  const setIsLoading = useWishlistStore((store) => store.setIsLoading);
  const setTokenBalance = useWishlistStore((store) => store.setTokenBalance);

  useEffect(() => {
    if (!user || !selectedGuild) return;

    const userGuildRef = doc(
      db,
      "userGuilds",
      `${user.uid}_${selectedGuild}`
    );
    const unsubscribe = onSnapshot(userGuildRef, (doc) => {
      if (doc.exists()) {
        setItems(doc.data()?.wishlist || []);
        setIsLocked(doc.data()?.wishlistLocked || false);
        setTokenBalance(doc.data()?.wishlistTokens || 0);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [user, selectedGuild]);

  return (
    <div className="bg-base-300 p-4 relative w-full">
      <div className="mb-2 flex flex-row justify-between items-center">
        <p className="text-xl">Items</p>
        <WishlistTokens />
      </div>
      <WishlistItemsList />
      <div className="my-3 divider" />
      <div className="flex flex-row justify-between items-center mb-2">
        <h6 className="text-xl">Your Wishlist</h6>
      </div>
      <WishlistSavedItemsList />
      {isLoading && <WishlistLoader />}
      {isLocked && <WishlistLock />}
    </div>
  );
};
